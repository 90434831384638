import { createRouter, createWebHashHistory } from "vue-router";
import HomeIndex from "../view/index.vue";

const routes = [
  {
    path: "/",
    name: "HomeIndex",
    component: HomeIndex,
    meta: { title: "创意作画" },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
