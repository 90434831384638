<template>
  <div @click="handleClickOutside">
    <i
      v-if="result.length > 1"
      @click="db()"
      class="iconfont db"
      >&#xe6a2;</i
    >
    <div class="aiheader flex flex-row justify-between items-center">
      <div class="flex flex-row items-center">
        <!-- <img src="https://cdn.leishen.cn/activity_m/qiye/logo.png" /> -->
        <i
          class="iconfont white"
          style="font-size: 30px; color: #00fde3; margin-left: 10px"
          >&#xe600;</i
        >
        <span class="white ml5">创意作画</span>
      </div>

      <i
        class="iconfont white"
        style="font-size: 26px"
        @click.stop="dele()"
        >&#xe609;</i
      >
    </div>

    <div class="flex flex-row justify-between items-center main">
      <div class="flex flex-col justify-start items-center fixleft">
        <div class="item flex flex-row justify-between items-center top_title">
          <div
            class="flex flex-row justify-start items-center"
            @click.stop="showlistshow"
          >
            <img
              :src="selet.img"
              class="iconsall"
              @click.stop="showlistshow"
            />
            <span
              class="f_14 white"
              @click.stop="showlistshow"
              >{{ selet.text }}</span
            >
          </div>

          <i
            class="iconfont icon-jiantoushang1 white"
            v-if="!showlist"
            @click.stop="showlistshow"
            >&#xe61c;</i
          >

          <i
            class="iconfont icon-jiantoushang1 white"
            v-else
            >&#xe61b;</i
          >

          <div
            class="selelist_new flex flex-col justify-start w100"
            @click.stop
            v-if="showlist"
          >
            <div
              class="flex flex-row justify-start items-center items-new"
              v-for="(item, index) in left"
              :key="index"
              @click.stop="seleleft(item)"
            >
              <img :src="item.img" />
              <span class="f_14 white">{{ item.text }}</span>
            </div>
          </div>
        </div>
        <template v-if="selet.text == '文本生成图像'">
          <div class="mainArea">
            <div
              class="inputWrap"
              style="height: 129px"
            >
              <div class="content">
                <textarea
                  v-model="wbtext"
                  autocomplete="off"
                  placeholder="试试输入你心中的画面，尽量描述具体，可以尝试用一些风格修饰词辅助你的表达。描述文字，以逗号隔开。 示例：大气，海盗船，满月航行"
                  class="ant-input"
                  style="
                    overflow-y: hidden;
                    height: 110px;
                    resize: none;
                    min-height: 110px;
                    max-height: 110px;
                  "
                ></textarea>
              </div>
            </div>
            <div class="flex flex-col w100 mt_20">
              <div class="flex flex-row justify-start w100 items-center">
                <i
                  class="iconfont white f_18"
                  style="font-size: 22px"
                  >&#xe804;</i
                >
                <span
                  class="f_14 white"
                  style="margin-left: 8px"
                  >咒语书</span
                >
              </div>
              <div
                class="tulist flex flex-row justify-between items-center flex-wrap mt_20"
              >
                <div
                  class="xtitem flex flex-col items-center justify-between"
                  v-for="(item, index) in tulist"
                  :key="index"
                  :class="seletu == index ? 'seleborder' : ''"
                  @click="selefg(item, index)"
                >
                  <i
                    class="iconfont seleicon"
                    v-if="seletu == index"
                  >
                    &#xe63b;
                  </i>
                  <img :src="item.img" />
                  <span
                    class="f_12"
                    :class="seletu == index ? 'white' : ''"
                    >{{ item.text }}</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="btnWrap flex justify-center items-center"
            @click="scth()"
            v-if="load == false"
          >
            生成创意画作
          </div>

          <div
            class="btnWrap flex justify-center items-center"
            v-else
          >
            生成中…
          </div>
        </template>
        <template v-else>
          <div class="upload">
            <el-upload
              class="upload-demo"
              drag
              action="https://www.thunderobot.com/api.php?method=System.Upload.uploadImage"
              multiple
              list-type="picture"
              :headers="headers"
              :http-request="uploadFile"
              :on-preview="handlePreview"
              :on-success="handleSuccess"
              :on-error="handleError"
              :show-file-list="false"
            >
              <template v-if="tu != ''">
                <img
                  :src="tu"
                  style="height: 100%; max-width: 100%"
                />
                <i
                  class="iconfont white laji"
                  @click.stop="deletu"
                  >&#xe63f;</i
                >
              </template>
              <template v-else>
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">拖动或点击我上传不超过10M的</div>
                <div class="el-upload__text">JPG、JPEG、PNG、BMP图片</div>
              </template>
            </el-upload>

            <div
              class="btnWrap flex justify-center items-center mt_40 w280"
              @click="scxs()"
              v-if="loadimg == false"
            >
              生成相似画作
            </div>

            <div
              class="btnWrap flex justify-center items-center mt_40 w280"
              v-else
            >
              生成中…
            </div>
          </div>
        </template>
      </div>

      <div class="rightmain">
        <div
          class="itemshow w100"
          v-for="(item, index) in result"
          :key="index"
        >
          <div class="flex flex-row justify-start items-center">
            <i
              class="iconfont white"
              v-if="item.dastyle == 1"
              >&#xe65a;</i
            >
            <i
              class="iconfont white"
              v-else
              >&#xe629;</i
            >
            <span class="f_14 white ml5"> {{ item.name }}</span>
            <span
              class="f_14 white ml5"
              v-if="item.dastyle == 1"
            >
              {{ item.style }}</span
            >

            <span
              class="f_14 white ml5"
              v-if="item.time"
            >
              {{ item.time }}</span
            >
            <span
              class="f_14 white ml5"
              v-else
              >Ai生成中请等待~</span
            >
          </div>
          <div
            class="flex flex-row justify-start items-center mt_20 fd"
            v-if="item.dastyle == 2"
            style="
              background: #202532;
              padding: 5px 10px;
              width: 60px;

              position: relative;
            "
          >
            <img
              :src="item.style"
              style="width: 24px; height: 24px; margin-right: 5px"
            />
            <span class="c_9 f_14">原图</span>
            <img
              :src="item.style"
              class="datu"
              style=""
            />
          </div>
          <template v-if="load == true && index == 0">
            <loadNew></loadNew>
          </template>

          <div
            class="tulist flex flex-row flex-wrap justify-between items-center mt_20"
            v-else
          >
            <div
              class="imglist"
              v-for="(item1, index1) in item.results"
              :key="index1"
              :style="item.dastyle == 1 ? { width: '500px' } : { width: '23%' }"
            >
              <img
                :src="item1.url"
                class="showtu"
              />

              <div class="rc-image-mask"></div>
              <a
                :href="item1.url"
                :download="item.name"
              >
                <i class="iconfont download white">&#xe60e;</i></a
              >
            </div>
          </div>
        </div>
        <div
          class="kong w100 flex flex-col justify-center items-center"
          v-if="result.length == 0"
          style="width: 80%; margin-top: 2%"
        >
          <img
            style="width: 500px; opacity: 0.85"
            src="https://cdn.leishen.cn/activity_m/ai/k4.png"
          />
          <span class="f_14 c_9">暂无生成数据，快去生成吧</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import loadNew from "../components/loadNew.vue";
import localforage from "localforage";
import { ElMessageBox } from "element-plus";
import { ElMessage } from "element-plus";
import { UploadFilled } from "@element-plus/icons-vue";
export default {
  name: "HomeIndex",
  components: { loadNew, UploadFilled },
  data() {
    return {
      uploadPercentage: 0,
      imgUrl: "",
      previewOptions: null,
      fetchData: false,
      tu: "",
      headers: {
        Accept: "application/json, text/plain, */*",
        // 可以自行添加其他headers
      },
      uploaddata: {
        encrypt_key: 1,
        file_path: "chatgpt",
      },
      showlist: false,
      load: false,
      loadimg: false,
      result: [],
      seletu: "all",
      wbtext: "", //文本生成图像描述
      style: "<auto>",
      left: [
        {
          img: "https://cdn.leishen.cn/activity_m/ai/z1.png",
          text: "文本生成图像",
        },
        {
          img: "https://cdn.leishen.cn/activity_m/ai/t2.png",
          text: "相似图像生成",
        },
      ],
      selet: {
        img: "https://cdn.leishen.cn/activity_m/ai/z1.png",
        text: "文本生成图像",
      },

      tulist: [
        {
          text: "3D卡通",
          img: "https://img.alicdn.com/imgextra/i2/O1CN01OJhm7X1GEGZq3ioj8_!!6000000000590-0-tps-132-132.jpg",
          value: "<3d cartoon>",
        },
        {
          text: "Q版",
          img: "https://img.alicdn.com/imgextra/i1/O1CN01MFW8PJ1Rs4mOiM6P2_!!6000000002166-0-tps-132-132.jpg",
          value: "<anime>",
        },
        {
          text: "油画",
          img: "https://img.alicdn.com/imgextra/i3/O1CN01v66vF01ujUCtsPhir_!!6000000006073-0-tps-144-144.jpg",
          value: "<oil painting>",
        },
        {
          text: "水彩",
          img: "https://img.alicdn.com/imgextra/i1/O1CN0127BIxo1eyZOm7UD6Y_!!6000000003940-0-tps-132-132.jpg",
          value: "<watercolor>",
        },

        {
          text: "素描",
          img: "https://img.alicdn.com/imgextra/i3/O1CN01jd1KNR25X5FvzUhUE_!!6000000007535-0-tps-132-132.jpg",
          value: "<sketch>",
        },
        {
          text: "中国画",
          img: "https://img.alicdn.com/imgextra/i2/O1CN01vWhH8025xeEFaxj0Y_!!6000000007593-0-tps-132-132.jpg",
          value: "<chinese painting>",
        },
        {
          text: "扁平插画",
          img: "https://img.alicdn.com/imgextra/i3/O1CN01Okgf0J1XV4a69QIil_!!6000000002928-0-tps-132-132.jpg",
          value: "<flat illustration>",
        },
      ],
    };
  },
  async created() {
    // 获取数据

    if (await localforage.getItem("list")) {
      this.result = await localforage.getItem("list");
    }
  },

  methods: {
    openPreview() {
      this.previewOptions = [
        {
          src: "https://dashscope-result-bj.oss-cn-beijing.aliyuncs.com/a31e9b17/20240124/1416/7aae5845-5343-4971-bbae-d5b628b33f25-1.png?Expires=1706163447&OSSAccessKeyId=LTAI5tQZd8AEcZX6KZV4G8qL&Signature=yMbcO8wz0nEekRdH5Y02cCni%2Bm0%3D",
          w: 1200,
          h: 900,
        },
      ];
    },
    handleClose() {
      this.previewOptions = null;
    },
    deletu() {
      this.tu = "";
    },
    uploadFile(uploadFile) {
      console.log(uploadFile);
      let formdata = new FormData();

      formdata.append("file", uploadFile.file);
      formdata.append("encrypt_key", 1);
      formdata.append("file_path", "chatgpt");

      return axios
        .post(uploadFile.action, formdata)
        .then((response) => {
          uploadFile.onSuccess(response.data);
          console.log(response);
          this.tu = JSON.parse(response.data).data.data;
        })
        .catch((error) => {
          uploadFile.onError(error);
        });
    },
    seleleft(e) {
      this.selet = e;
      this.showlist = false;
    },
    showlistshow() {
      this.showlist = true;
      console.log(22);
    },
    handleClickOutside() {
      if (this.showlist) {
        this.showlist = false;
      }
      console.log(111);
    },
    dele() {
      let that = this;
      localforage
        .clear()
        .then(function () {
          // 当所有数据都已删除后，运行此方法
          console.log("All data removed");
          that.result = [];
        })
        .catch(function (err) {
          // 当出错时，这个方法会运行
          console.log("Unable to remove all data", err);
        });
    },
    async addFirst(key, value) {
      // 先获取数组
      let arr = await localforage.getItem(key);

      // 如果不存在数组就创建一个
      if (!arr) {
        arr = [];
      }

      // 在数组的第一个位置添加元素
      arr.unshift(value);

      // 将结果存储回去
      localforage
        .setItem(key, arr)
        .then(() => console.log("Item added at first"))
        .catch((err) => console.error("Error adding item", err));
    },

    selefg(e, index) {
      this.seletu = index;
      this.style = e.value;
    },
    db() {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    async scxs() {
      if (this.tu == "") {
        ElMessage.error("请先上传图片");
        return false;
      }
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      if (navigator.storage && navigator.storage.estimate) {
        const estimation = await navigator.storage.estimate();
        const percentUsed = estimation.usage / estimation.quota;
        const usageInMB = Math.round(estimation.usage / 1024 / 1024);

        console.log(
          `Using ${usageInMB}MB out of ${
            estimation.quota
          } bytes, which is ${Math.round(percentUsed * 100)}%.`
        );

        if (percentUsed > 0.3) {
          // 修改此处的值，根据你的需求来判断是否超过阈值
          ElMessageBox.alert("数据太多了，请先清除~", "温馨提示", {});
        }
      } else {
        console.error("StorageManager not found");
      }
      this.load = true;
      let newlist = {
        name: "生成相似图",
        time: "",
        style: this.tu, //原图
        results: "",
        dastyle: 2, //1文本生成图像  2图生图
      };
      this.result.unshift(newlist);
      this.result;

      axios
        .post("https://leishenai.thunderobot.com/tushengtu_upload", {
          img: this.tu,
        })
        .then((response) => {
          console.log(response);
          let task_id = JSON.parse(response.data).output.task_id;
          console.log(task_id);
          let self = this;
          let maxRetryCount = 10; // 设置最大重试次数
          let retryCount = 0;

          let fetchData = async function () {
            while (retryCount < maxRetryCount) {
              try {
                self.fetchData = true;
                const response = await axios.post(
                  "https://leishenai.thunderobot.com/tushengtu_get",
                  {
                    task_id: task_id,
                  }
                );
                if (
                  JSON.parse(response.data).output.task_status == "SUCCEEDED"
                ) {
                  console.log("请求成功，终止请求");

                  let list = {
                    name: "相似图像",
                    time: JSON.parse(response.data).output.end_time,
                    style: self.tu,
                    results: JSON.parse(response.data).output.results,
                    dastyle: 2, //1文本生成图像  2图生图
                  };
                  self.result[0] = list;
                  self.load = false;
                  self.addFirst("list", list);
                  self.tu = "";
                  break;
                } else {
                  console.log("请求未成功，正在重新请求");
                }
              } catch (error) {
                retryCount += 1;
                console.log("请求失败，正在重新请求", error);
              } finally {
                self.fetching = false;
              }
            }
          };

          fetchData();
        });
    },
    async scth() {
      if (this.wbtext == "") {
        ElMessage.error("请先描述内容");
        return false;
      }
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      if (navigator.storage && navigator.storage.estimate) {
        const estimation = await navigator.storage.estimate();
        const percentUsed = estimation.usage / estimation.quota;
        const usageInMB = Math.round(estimation.usage / 1024 / 1024);

        console.log(
          `Using ${usageInMB}MB out of ${
            estimation.quota
          } bytes, which is ${Math.round(percentUsed * 100)}%.`
        );

        if (percentUsed > 0.3) {
          // 修改此处的值，根据你的需求来判断是否超过阈值
          ElMessageBox.alert("数据太多了，请先清除~", "温馨提示", {});
        }
      } else {
        console.error("StorageManager not found");
      }
      this.load = true;
      let newlist = {
        name: this.wbtext,
        time: "",
        style: this.seletu != "all" ? this.tulist[this.seletu].text : "",
        results: "",
        dastyle: 1, //1文本生成图像  2图生图
      };
      this.result.unshift(newlist);
      this.result;

      axios
        .post("https://leishenai.thunderobot.com/wenshengtu", {
          prompt: this.wbtext,
          style: this.style,
        })

        .then((response) => {
          console.log(response);
          let res = response.data.data;
          let list = {
            name: this.wbtext,
            time: res.end_time,
            style: this.seletu != "all" ? this.tulist[this.seletu].text : "",
            results: res.results,
            dastyle: 1, //1文本生成图像  2图生图
          };
          this.result[0] = list;
          this.load = false;
          this.addFirst("list", list);
          this.wbtext = "";
          this.seletu = "all";
        });
    },
  },
};
</script>

<style lang="scss">
.fd {
  cursor: pointer;
  .datu {
    width: 200px;
    height: auto;
    position: absolute;
    left: 0;
    display: none;
    top: 40px;
    z-index: 9;
    border-radius: 10px;
  }
}
.fd:hover .datu {
  display: block;
}
.upload {
  width: 90%;
  margin: 20px 0;
}
.el-upload-dragger {
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
  position: relative;
  .laji {
    font-size: 16px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
    background: rgba(0, 0, 0, 0.5);
  }
}
.el-upload-dragger {
  background: #353c4f !important;
}
.el-upload-dragger:hover {
  border-color: #00f2f5;
}
.el-upload__text {
  color: #d7d7d7 !important;
}
.selelist_new {
  position: absolute;
  left: 0;
  top: 50px;
  background-color: #202532;
  border-bottom: 1px solid rgba(135, 138, 171, 0.2);
  border-radius: 0 0 2px 2px;
  box-shadow: 0 4px 20px 0 rgba(62, 68, 95, 0.09);
  color: #aeafb1;
  padding: 21px 12px;
  padding-top: 5px;
  width: 100%;
  box-sizing: border-box;
  z-index: 99;
  img {
    width: 20px;
    margin-right: 5px;
  }
  .items-new {
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    margin: 5px 0;
  }
  .items-new:hover {
    background: rgba(135, 138, 171, 0.2);
  }
}
.db {
  font-size: 42px;
  position: fixed;
  right: 20px;
  bottom: 30px;
  color: #00f2f5;
  z-index: 9;
  cursor: pointer;
}
/* 不过，这种方法在 IE、Edge 和 Firefox 上可能不那么有效 */
.aiheader {
  width: 100%;
  padding: 20px 40px;
  box-sizing: border-box;
  background: #011524;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
}
.imglist {
  width: 22%;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  img {
    width: 100%;
  }
}
.download {
  font-size: 80px;
  position: absolute;
  left: 35%;
  top: 40%;
  color: #fff;
  z-index: 99;
  opacity: 0;
}
.rc-image-mask {
  align-items: center;
  background-image: linear-gradient(180deg, transparent, #000);
  bottom: 0;
  color: var(--wanx-wh);
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 98;
}
.imglist:hover {
  .download {
    opacity: 1;
  }
  .rc-image-mask {
    opacity: 1;
  }
}
.showtu {
  border-radius: 10px;
}
.rightmain {
  background: var(--wanx-bk);
  flex: 1;
  min-width: 906px;
  padding-left: 374px;
  margin-top: 60px;
  .itemshow {
    margin-top: 20px;
  }
}
.btnWrap {
  cursor: pointer;
  color: #000;
  font-size: 14px;
  background: -moz-linear-gradient(
    left,
    #00c8f5 0%,
    #00fde3 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #00c8f5 0%,
    #00fde3 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #00c8f5 0%,
    #00fde3 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00c8f5', endColorstr='#00fde3',GradientType=1 ); /* IE6-9 */
  border: 1px solid var(--wanx-brand-main);
  box-shadow: 0 4px 20px 0 rgba(62, 68, 95, 0.09);
  width: 300px;
  height: 40px;
  padding: 0 9px;
  font-size: 16px;
  line-height: 22px;
  border-radius: 40px;
  margin-bottom: 40px;
}
.tulist::after {
  width: 63px;
  height: 0;
  content: "";
}
.xtitem {
  position: relative;
  width: 63px;
  border: 1px solid #2d3240;
  margin-bottom: 20px;
  border-radius: 5px;
  img {
    width: 63px;
    height: 63px;
  }
  span {
    padding: 5px 0;
    color: #999;
  }
  .seleicon {
    color: #00fde3;
    background: #000;
    position: absolute;
    border-radius: 50%;
    right: -5px;
    top: -5px;
  }
}
.seleborder {
  border: 1px solid #00fde3;
}
.main {
  width: 100%;
  padding: 40px;
}
h1 {
  color: blue;
}
.iconsall {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.fixleft {
  width: 340px;
  border: 1px solid rgba(135, 138, 171, 0.3);
  border-radius: 20px;
  overflow: hidden;
  position: fixed;
  left: 40px;
  top: 100px;

  .top_title {
    width: 100%;
    padding: 30px;
    align-items: center;
    background-color: #2d3240;
    display: flex;
    height: 40px;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
  }
}
.mainArea {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.inputWrap {
  width: 100%;
  .content {
    background: #0f1319;
    border-radius: 2px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    textarea {
      background: transparent !important;
      border: none !important;
      border-radius: 2px 2px 0 0;
      box-shadow: none !important;
      caret-color: var(--wanx-wh);
      color: hsla(0, 0%, 100%, 0.85);
      padding: 0;
      width: 100%;
      height: calc(100% - 42px) !important;
      max-height: calc(100% - 42px) !important;
    }
    textarea:focus {
      outline: none;
    }
  }
}
</style>
