<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script></script>

<style>
html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #070c14;
  overflow-x: hidden;
  box-sizing: border-box;
}
html {
  font-size: 20px;
}
body {
  transform-origin: left top;
  background-size: 100% 100%;
}
/* Webkit 浏览器，如 Safari 和 Chrome */
#app::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

/* 并非所有的 Firefox 版本都支持这个属性 */
body {
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
  scrollbar-width: thin;
}
</style>
