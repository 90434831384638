import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "vue-photo-preview/dist/skin.css";
import "./assets/common.css";
import router from "./router";
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "创意作画";
  next();
});
createApp(App).use(ElementPlus).use(router).mount("#app");
